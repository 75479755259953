import React, {Component} from 'react';
import './App.css';
import './New.css';
import Container from "reactstrap/es/Container";
import Row from "reactstrap/es/Row";
import Col from "reactstrap/es/Col";
import $ from "jquery";
import Swiper from 'swiper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretRight } from '@fortawesome/free-solid-svg-icons'
import AOS from 'aos';
import 'aos/dist/aos.css';

class App extends Component {
  constructor() {
    super();

    this.state = {
      safari: false,
    }
  }

  componentDidMount() {
    var acc = document.getElementsByClassName("accordion");
    var i;

    for (i = 0; i < acc.length; i++) {
      acc[i].addEventListener("click", function() {
        this.classList.toggle("active");
        var panel = document.getElementById("panel");
        if (panel.style.maxHeight) {
          panel.style.maxHeight = null;
        } else {
          panel.style.maxHeight = panel.scrollHeight + "px";
        }
      });
    }

    if (navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1) {
      this.setState({
        safari: true,
      })
    } else {
      AOS.init()
    }

    // $(window).on("load", function () {
    //   function fade() {
    //     $('.fade').each(function () {
    //       /* Check the location of each desired element */
    //       var objectBottom = $(this).offset().top + $(this).outerHeight();
    //       var windowBottom = $(window).scrollTop() + 1.1 * $(window).innerHeight();
    //
    //       /* If the object is completely visible in the window, fade it in */
    //       if (objectBottom < windowBottom) {
    //         if ($(this).css('opacity') == 0) {
    //           $(this).fadeTo(500, 1);
    //         }
    //       } else {
    //         if ($(this).css('opacity') == 1) {
    //           $(this).fadeTo(500, 0);
    //         }
    //       }
    //     });
    //   }
    //
    //   fade(); //Fade in completely visible elements during page-load
    //   $(window).scroll(function () {
    //     fade();
    //   }); //Fade in elements during scroll
    // });

    var body = $("html, body");
    new Swiper('.swiper-container', {
      // Optional parameters
      direction: 'horizontal',
      loop: true,

      autoplay: {
        delay: 5000,
      },

      // If we need pagination
      pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
        clickable: true,
      },
    })
    $(".hamburger").click(function (e) {
      e.preventDefault();
      $(".ext-hamburger").fadeToggle(450);
      $("#mobile-menu").addClass("black").addClass("scrolling").toggleClass("burger");
      if ($(document).scrollTop() > 0 && $(document).scrollTop() < 720 && $(".menu").hasClass("black")) {
        $("#mobile-menu").removeClass("black")
      }
    });
    $(".inicio").click(function (e) {
      e.preventDefault();
      $(".ext-hamburger").fadeToggle(450);
      $("#mobile-menu").toggleClass("black-menu");
      $(".inicio").addClass("active");
      $(".servicios").removeClass("active");
      $(".contacto").removeClass("active");
      $(".nosotros").removeClass("active");
      body.stop().animate({scrollTop: 0}, 500, 'swing', function () {
      });
    });
    $(".nosotros").click(function (e) {
      e.preventDefault();
      $(".ext-hamburger").fadeToggle(450);
      $(".inicio").removeClass("active");
      $(".servicios").removeClass("active");
      $(".contacto").removeClass("active");
      $(".nosotros").addClass("active");
      body.stop().animate({scrollTop: $("#nosotros").offset().top - 100}, 500, 'swing', function () {
      });
    });
    $(".servicios").click(function (e) {
      e.preventDefault();      $(".ext-hamburger").fadeToggle(450);
      $(".inicio").removeClass("active");
      $(".servicios").addClass("active");
      $(".contacto").removeClass("active");
      $(".nosotros").removeClass("active");
      body.stop().animate({scrollTop: $("#servicios").offset().top - 100}, 500, 'swing', function () {
      });
    });
    $(".contacto").click(function (e) {
      e.preventDefault();
      $(".ext-hamburger").fadeToggle(450);
      $(".inicio").removeClass("active");
      $(".servicios").removeClass("active");
      $(".contacto").addClass("active");
      $(".nosotros").removeClass("active");
      body.stop().animate({scrollTop: $("#contacto").offset().top}, 500, 'swing', function () {
      });
    });
    document.addEventListener('scroll', function (event) {
      event.preventDefault()
      if ($(document).scrollTop() >= ($("#nosotros").offset().top - 100)) {
        $(".inicio").removeClass("active");
        $(".servicios").removeClass("active");
        $(".contacto").removeClass("active");
        $(".nosotros").addClass("active");
      } if ($(document).scrollTop() >= ($("#servicios").offset().top - 100)) {
        $(".inicio").removeClass("active");
        $(".servicios").addClass("active");
        $(".contacto").removeClass("active");
        $(".nosotros").removeClass("active");
      } if($(document).scrollTop() >= ($("#contacto").offset().top - 500)) {
        $(".inicio").removeClass("active");
        $(".servicios").removeClass("active");
        $(".contacto").addClass("active");
        $(".nosotros").removeClass("active");
      } if ($(document).scrollTop() <= ($("#nosotros").offset().top - 150)) {
        $(".inicio").addClass("active");
        $(".servicios").removeClass("active");
        $(".contacto").removeClass("active");
        $(".nosotros").removeClass("active");
      }

      if ($(document).scrollTop() > 575) {
        event.preventDefault()
        $("#rojo2-1").animate({opacity: 1}, {
          duration: 350,
          queue: false
        }).animate({"margin-top": "-75px"}, {duration: 200, queue: false});
      } else {
        $("#rojo2-1").animate({opacity: 0}, {duration: 350, queue: false}).animate({"margin-top": "0"}, {
          duration: 200,
          queue: false
        });
      }
      if ($(document).scrollTop() > 720) {
        event.preventDefault()
        $('.menu').addClass("scrolling").addClass("black")
        $('.app-header').addClass("small")
      } else {
        if (!$(".menu").hasClass("burger")) {
          $('.menu').removeClass("scrolling").removeClass("black")
          $('.app-header').removeClass("small")
        }
      }
      if ($(document).scrollTop() > 1300) {
        event.preventDefault()
        $("#rojo2-2").animate({opacity: 1}, {
          duration: 350,
          queue: false
        }).animate({"margin-top": "-75px"}, {duration: 200, queue: false});
      } else {
        $("#rojo2-2").animate({opacity: 0}, {duration: 350, queue: false}).animate({"margin-top": "0"}, {
          duration: 200,
          queue: false
        });
      }
      if ($(document).scrollTop() > 2000) {
        event.preventDefault()
        $("#rojo2-3").animate({opacity: 1}, {
          duration: 350,
          queue: false
        }).animate({"margin-top": "-75px"}, {duration: 200, queue: false});
      } else {
        $("#rojo2-3").animate({opacity: 0}, {duration: 350, queue: false}).animate({"margin-top": "0"}, {
          duration: 200,
          queue: false
        });
      }
    }, true /*Capture event*/);
  }

  render() {
    return (
      <div className="app" id="app">
        <div className="menu hide-sm" id="regular-menu">
          <Container className="main-container">
            <Row>
              <Col md="12" className="app-header">
                <Row>
                  <Col xs="7" className="text-left">
                    <img className="exp-logo" src={require("./images/logo.png")} alt="Logo"/>
                  </Col>
                  <Col xs="5" className="text-center">
                    <Row className="links">
                      <Col className="menu-link">
                        <p className="menu-link-text inicio active">Inicio</p>
                      </Col>
                      <Col className="menu-link">
                        <p className="menu-link-text nosotros">Nosotros</p>
                      </Col>
                      <Col className="menu-link">
                        <p className="menu-link-text servicios">Servicios</p>
                      </Col>
                      <Col className="menu-link">
                        <p className="menu-link-text contacto">Contacto</p>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="menu hide-md" id="mobile-menu">
          <Container className="main-container">
            <Row>
              <Col md="12" className="app-header">
                <Row>
                  <Col xs="7" className="text-left">
                    <img className="mob-logo" src={require("./images/logo.png")} alt="Logo"/>
                  </Col>
                  <Col xs="5" className="justify-content-end">
                    <Row className="links">
                      <div className="menu-link">
                        <img className="hamburger" src={require("./images/burger.png")} alt="Menu"/>
                      </div>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="section_wide section_black ext-hamburger hide-md">
          <Container className="main-container">
            <Col xs="12" className="text-center">
              <Row className="links">
                <Col xs="12" className="menu-link">
                  <p className="menu-link-text inicio">Inicio</p>
                </Col>
                <Col xs="12" className="menu-link">
                  <p className="menu-link-text nosotros">Nosotros</p>
                </Col>
                <Col xs="12" className="menu-link">
                  <p className="menu-link-text servicios">Servicios</p>
                </Col>
                <Col xs="12" className="menu-link">
                  <p className="menu-link-text contacto">Contacto</p>
                </Col>
              </Row>
            </Col>
          </Container>
        </div>
        <div className="section_wide section_first">
          <Container className="main-container section_intro">
            <div className="swiper-container">
              <div className="swiper-wrapper">
                <div className="swiper-slide">
                  <Row>
                    <Col>
                      <h4 className="color-red">Nuestro Core</h4>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <h1>MARKETING <br className="hide-md"/><span className="color-red">+</span> <br
                        className="hide-md"/>ESTRATEGIA</h1>
                    </Col>
                  </Row>
                </div>
                <div className="swiper-slide">
                  <Row>
                    <Col>
                      <h4>El secreto de <span className="diff">la fōrmula</span> es</h4>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <h1>Conectar las necesidades <br className="hide-sm"/>de<br className="hide-md"/> nuestro cliente
                        y el éxito.</h1>
                    </Col>
                  </Row>
                </div>
                <div className="swiper-slide">
                  <Row>
                    <Col>
                      <h4><span className="diff">La suma</span> como concepto rector</h4>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <h1>Nuestro mejor aporte a las<br/>marcas es sumarles valor.</h1>
                    </Col>
                  </Row>
                </div>
              </div>
              <div className="swiper-pagination"></div>
            </div>
            <Row className="outro">
              <Col>
                <p><img src={require("./images/arrow_down.png")} alt=""/><span className="know_us">Conocenos</span></p>
              </Col>
            </Row>
          </Container>
        </div>
        <Container className="section_overlay">
          <Row>
            <Col>
              <h1>Somos una agencia que trabaja fuertemente en <br/>encontrar soluciones integrales para nuestros
                clientes.</h1>
            </Col>
          </Row>
        </Container>
        <Container className="section_padding">
          <Row>
            <Col>
              <h4>Nuestro Core</h4>
            </Col>
          </Row>
          <Row>
            <Col>
              <h1>Marketing <span className="plussign">+</span> <span className="spaced">Estrategia</span></h1>
            </Col>
          </Row>
          <Row>
            <Col md="8">
              <p className="content content_strategy">Tenemos una visión estratégica que acompaña nuestra forma de hacer
                marketing y
                comunicación, con un plus creativo
                y diseño innovador.
                <br/>
                <br/>
                Producimos contenidos de impacto que diferencian y potencian a cada proyecto.</p>
            </Col>
            <Col md={{size: 12}} xl={{size: 3, offset: 1}}>
              <img className="rojo" src={require("./images/rojo.png")} alt=""/>
            </Col>
          </Row>
        </Container>
        <div className="app">
          <div className="section_wide section_gray" id="nosotros">
            <Container className="main-container section_padding">
              <Row>
                <Col md="2">
                  <img className="rojo2 hide-sm" id="rojo2-1" src={require("./images/rojoj.png")} alt=""/>
                </Col>
                <Col md="8" className="text-center">
                  <Row>
                    <Col>
                      <h4>Quiénes Somos</h4>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <h1><span className="plus-sign">+</span> de 20 años de trayectoria corporativa</h1>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <div className="hide-md">
                <Row>
                  <Col sm="12" className="vertical-center">
                    <p className="we-text">Somos fuertes en inspirar ideas, concretar acciones
                      y generar aportes de valor a cada proyecto.
                      <br/><br/>
                      Nos desempeñamos con profesionalismo desde distintos ámbitos para marcas de primera línea.
                      <br/><br/>
                      Trabajamos para generar credibilidad y confianza,
                      por ser el activo más importante para nosotros.
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col sm="12" className="vertical-center justify-content-center mt-2">
                    <img className="img-fluid" src={require("./images/owner.png")} alt=""/>
                  </Col>
                </Row>
                <Row>
                  <Col sm="12" className="vertical-center mt-2 justify-content-center">
                    <p className="p-owner"><span className="plus-sign">Marina Nuñez</span><br/>Owner & Director</p>
                  </Col>
                </Row>
                <Row>
                  <Col sm="12" className="">
                    <div className="accordion text-center"><FontAwesomeIcon icon={faCaretRight} className="caret"/>Ver perfil</div>
                  </Col>
                </Row>
              </div>
              <Row className="hide-sm">
                <Col md={{size: 10, offset: 1}}>
                  <Row className="p-2">
                    <Col md="5">
                      <img className="content-right" src={require("./images/owner.png")} alt=""/>
                      <div className="accordion content-right"><FontAwesomeIcon icon={faCaretRight} className="caret"/>Ver perfil</div>
                    </Col>
                    <Col md="7" className="vertical-center">
                      <div className="content-left content-overlap-left content-card content-card-limited">
                        <p className="we-text">Somos fuertes en inspirar ideas, concretar acciones
                          y generar aportes de valor a cada proyecto.
                          <br/><br/>
                          Nos desempeñamos con profesionalismo desde distintos ámbitos para marcas de primera línea.
                          <br/><br/>
                          Trabajamos para generar credibilidad y confianza,
                          por ser el activo más importante para nosotros.
                        </p>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col md={{size: 10, offset: 1}}>
                  <Row className="p-2">
                    <div className="panel" id="panel">
                      <p className="bio">Profesional con distintiva experiencia corporativa en empresas de consumo masivo y servicios (Coca-Cola Femsa, Mattel Toys, Warner Lambert, Philips & Costa Cruceros).
                        <br/><br/>
                        En los últimos años, ya desde la consultoría de imagen y negocios, brindó servicios como Directora de Planeamiento Estratégico en agencias de publicidad dándole soporte a compañías de primera línea.
                        <br/><br/>
                        Actualmente dirige un equipo compuesto por profesionales de distintas disciplinas -marketing, publicidad, administración, relaciones públicas y humanas- capaces de realizar gestiones exitosas para distintas empresas clientes.</p>
                    </div>
                  </Row>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
        <Container className="section_padding" id="servicios">
          <Row>
            <Col className="text-center">
              <Row>
                <Col>
                  <h4>Servicios</h4>
                </Col>
              </Row>
              <Row>
                <Col>
                  <h1>Brindamos soluciones. Sumamos valor.</h1>
                </Col>
              </Row>
            </Col>
          </Row>
          {/*--- DESKTOP ---*/}
          <div className="hide-sm">
            <Row className="padding-top" data-aos={!this.state.safari ? "fade-up" : ""} data-aos-duration={!this.state.safari ? "500" : ""} data-aos-offset={!this.state.safari ? "200" : ""}>
              <img className="rojo2 rojo2-right hide-sm" id="rojo2-2" src={require("./images/rojoj.png")} alt=""/>
              <Col md={{size: 4, offset: 1}} className="vertical-center">
                <div className="content-right content-overlap-right content-card z1 p-40">
                  <Row>
                    <Col>
                      <h2>Estrategias Tailormade</h2>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <p className="services-text">Trazamos las estrategias más apropiadas para cada proyecto.</p>
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col md="6" className="vertical-center">
                <img src={require("./images/serv-1.jpg")} alt=""/>
              </Col>
            </Row>
            <Row className="padding-top" data-aos={!this.state.safari ? "fade-up" : ""} data-aos-duration={!this.state.safari ? "500" : ""} data-aos-offset={!this.state.safari ? "200" : ""}>
              <Col md={{size: 6, offset: 1}} className="vertical-center">
                <img src={require("./images/serv-2.jpg")} alt=""/>
              </Col>
              <Col md="4" className="vertical-center">
                <div className="content-left content-overlap-left-90 content-card z1 p-4">
                  <Row>
                    <Col>
                      <h2>Desarrollamos acciones omnichannel:</h2>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <ul>
                        <li>
                          <span className="content-span">+</span>Trade Marketing
                        </li>
                        <li>
                          <span className="content-span">+</span>Consumer & Shopper Marketing
                        </li>
                        <li>
                          <span className="content-span">+</span>Endomarketing
                        </li>
                      </ul>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
            <Row className="padding-top" data-aos={!this.state.safari ? "fade-up" : ""} data-aos-duration={!this.state.safari ? "500" : ""} data-aos-offset={!this.state.safari ? "200" : ""}>
              <img className="rojo2 hide-sm" id="rojo2-3" src={require("./images/rojoj.png")} alt=""/>
              <Col md={{size: 4, offset: 1}} className="vertical-center">
                <div className="content-right content-overlap-right content-card z1 p-40">
                  <Row>
                    <Col>
                      <h2>Lanzamientos y Campañas</h2>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <p className="services-text">Planificamos e implementamos lanzamiento de producto, campañas y
                        promociones.</p>
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col md="6" className="vertical-center">
                <img src={require("./images/serv-3.jpg")} alt=""/>
              </Col>
            </Row>
            <Row className="padding-top" data-aos={!this.state.safari ? "fade-up" : ""} data-aos-duration={!this.state.safari ? "500" : ""} data-aos-offset={!this.state.safari ? "200" : ""}>
              <Col md={{size: 6, offset: 1}} className="vertical-center">
                <img src={require("./images/serv-4.jpg")} alt=""/>
              </Col>
              <Col md="4" className="vertical-center">
                <div className="content-left content-overlap-left-90 content-card z1 p-4">
                  <Row>
                    <Col>
                      <h2>Contenidos de Impacto</h2>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <p className="services-text">Diferenciamos y potenciamos cada marca / proyecto y elaboramos un
                        plan para sostenerlo.</p>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
            <Row className="padding-top" data-aos={!this.state.safari ? "fade-up" : ""} data-aos-duration={!this.state.safari ? "500" : ""} data-aos-offset={!this.state.safari ? "200" : ""}>
              <Col md={{size: 4, offset: 1}} className="vertical-center">
                <div className="content-right content-overlap-right content-card z1 p-40">
                  <Row>
                    <Col>
                      <p className="services-text">Como equipo especializado, brindamos servicio de <span
                        className="content-red font-weight-bold">Consultoría en Marketing Estratégico.</span></p>
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col md="6" className="vertical-center">
                <img src={require("./images/serv-5.jpg")} alt=""/>
              </Col>
            </Row>
            <Row className="padding-top" data-aos={!this.state.safari ? "fade-up" : ""} data-aos-duration={!this.state.safari ? "500" : ""} data-aos-offset={!this.state.safari ? "200" : ""}>
              <Col md={{size: 6, offset: 1}} className="vertical-center">
                <img src={require("./images/serv-6.jpg")} alt=""/>
              </Col>
              <Col md="4" className="vertical-center">
                <div className="content-left content-overlap-left-90 content-card z1 p-4">
                  <Row>
                    <Col>
                      <p className="services-text">Aplicamos <span
                        className="content-red font-weight-bold">Tecnología</span> para
                        comprender hábitos del consumidor y potenciar la experiencia de compra.</p>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
            <Row className="padding-top" data-aos={!this.state.safari ? "fade-up" : ""} data-aos-duration={!this.state.safari ? "500" : ""} data-aos-offset={!this.state.safari ? "200" : ""}>
              <Col md={{size: 4, offset: 1}} className="vertical-center">
                <div className="content-right content-overlap-right content-card z1 p-35">
                  <Row>
                    <Col>
                      <h2>Comunicación Interna</h2>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <ul>
                        <li>
                          <span className="content-span">+</span>Campaña de comunicación
                        </li>
                        <li>
                          <span className="content-span">+</span>Workshops y capacitaciones
                        </li>
                        <li>
                          <span className="content-span">+</span>Dinámicas grupales
                        </li>
                      </ul>
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col md="6" className="vertical-center">
                <img src={require("./images/serv-7.jpg")} alt=""/>
              </Col>
            </Row>
            <Row className="padding-top" data-aos={!this.state.safari ? "fade-up" : ""} data-aos-duration={!this.state.safari ? "500" : ""} data-aos-offset={!this.state.safari ? "200" : ""}>
              <Col md={{size: 6, offset: 1}} className="vertical-center">
                <img src={require("./images/serv-8.jpg")} alt=""/>
              </Col>
              <Col md="4" className="vertical-center">
                <div className="content-left content-overlap-left-90 content-card z1 p-4">
                  <Row>
                    <Col>
                      <h2>Concursos e incentivos</h2>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <p className="services-text">Sabemos de motivación para promover cambios de hábito en los
                        equipos.</p>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
            <Row className="padding-top" data-aos={!this.state.safari ? "fade-up" : ""} data-aos-duration={!this.state.safari ? "500" : ""} data-aos-offset={!this.state.safari ? "200" : ""}>
              <Col md={{size: 4, offset: 1}} className="vertical-center">
                <div className="content-right content-overlap-right content-card z1 p-30">
                  <Row>
                    <Col>
                      <h2>Construimos comunicaciones efectivas a través de:</h2>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <ul>
                        <li>
                          <span className="content-span">+</span>Presentaciones
                        </li>
                        <li>
                          <span className="content-span">+</span>Videos
                        </li>
                        <li>
                          <span className="content-span">+</span>Storytelling
                        </li>
                      </ul>
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col md="6" className="vertical-center">
                <img src={require("./images/serv-9.jpg")} alt=""/>
              </Col>
            </Row>
            <Row className="padding-top" data-aos={!this.state.safari ? "fade-up" : ""} data-aos-duration={!this.state.safari ? "500" : ""} data-aos-offset={!this.state.safari ? "200" : ""}>
              <Col md={{size: 6, offset: 1}} className="vertical-center">
                <img src={require("./images/serv-10.jpg")} alt=""/>
              </Col>
              <Col md="4" className="vertical-center">
                <div className="content-left content-overlap-left-90 content-card z1 p-4">
                  <Row>
                    <Col>
                      <p className="services-text">Organizamos <span
                        className="color-red font-weight-bold">eventos</span><br/> y megaeventos, <span
                        className="color-red font-weight-bold">activaciones</span><br/> y todo tipo de
                        <span className="color-red font-weight-bold"> experiencias.</span></p>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </div>
          {/*--- MOBILE ---*/}
          <div className="hide-md mt-4">
            <Row>
              <Col sm="12" className="vertical-center">
                <img className="img-fluid" src={require("./images/serv-1.jpg")} alt=""/>
              </Col>
            </Row>
            <div className="content-card">
              <Row>
                <Col>
                  <h2>Estrategias Tailormade</h2>
                </Col>
              </Row>
              <Row>
                <Col>
                  <p className="services-text">Trazamos las estrategias más apropiadas para cada proyecto</p>
                </Col>
              </Row>
            </div>
            <Row>
              <Col sm="12" className="vertical-center">
                <img className="img-fluid" src={require("./images/serv-2.jpg")} alt=""/>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="content-card z1">
                  <Row>
                    <Col>
                      <h2>Desarrollamos acciones omnichannel:</h2>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <ul>
                        <li>
                          <span className="content-span">+</span>Trade Marketing
                        </li>
                        <li>
                          <span className="content-span">+</span>Consumer & Shopper Marketing
                        </li>
                        <li>
                          <span className="content-span">+</span>Endomarketing
                        </li>
                      </ul>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
            <Row>
              <Col sm="12" className="vertical-center">
                <img className="img-fluid" src={require("./images/serv-3.jpg")} alt=""/>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="content-card z1 p-40">
                  <Row>
                    <Col>
                      <h2>Lanzamientos y Campañas</h2>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <p className="services-text">Planificamos e implementamos lanzamiento de producto, campañas y
                        promociones.</p>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
            <Row>
              <Col sm="12" className="vertical-center">
                <img className="img-fluid" src={require("./images/serv-4.jpg")} alt=""/>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="content-card z1 p-40">
                  <Row>
                    <Col>
                      <h2>Contenidos de impacto</h2>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <p className="services-text">Diferenciamos y potenciamos cada marca / proyecto y elaboramos un plan para sostenerlo.</p>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
            <Row>
              <Col sm="12" className="vertical-center">
                <img className="img-fluid" src={require("./images/serv-5.jpg")} alt=""/>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="content-card z1 p-40">
                  <Row>
                    <Col>
                      <p className="services-text">Como equipo especializado, brindamos servicio de <span
                        className="content-red font-weight-bold">Consultoría en Marketing Estratégico</span></p>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
            <Row>
              <Col sm="12" className="vertical-center">
                <img className="img-fluid" src={require("./images/serv-6.jpg")} alt=""/>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="content-card z1 p-40">
                  <Row>
                    <Col>
                      <p className="services-text">Aplicamos <span
                        className="content-red font-weight-bold">Tecnología</span> para
                        comprender hábitos del consumidor y potenciar la experiencia de compra</p>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
            <Row>
              <Col sm="12" className="vertical-center">
                <img className="img-fluid" src={require("./images/serv-7.jpg")} alt=""/>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="content-card z1">
                  <Row>
                    <Col>
                      <h2>Comunicación interna</h2>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <ul>
                        <li>
                          <span className="content-span">+</span>Campaña de comunicación
                        </li>
                        <li>
                          <span className="content-span">+</span>Workshops y capacitaciones
                        </li>
                        <li>
                          <span className="content-span">+</span>Dinámicas grupales
                        </li>
                      </ul>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
            <Row>
              <Col sm="12" className="vertical-center">
                <img className="img-fluid" src={require("./images/serv-8.jpg")} alt=""/>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="content-card z1 p-40">
                  <Row>
                    <Col>
                      <h2>Concursos e incentivos</h2>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <p className="services-text">Sabemos de motivación para promover cambios de hábito en los equipos.</p>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
            <Row>
              <Col sm="12" className="vertical-center">
                <img className="img-fluid" src={require("./images/serv-9.jpg")} alt=""/>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="content-card z1">
                  <Row>
                    <Col>
                      <h2>Construimos comunicaciones efectivas a través de:</h2>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <ul>
                        <li>
                          <span className="content-span">+</span>Presentaciones
                        </li>
                        <li>
                          <span className="content-span">+</span>Videos
                        </li>
                        <li>
                          <span className="content-span">+</span>Storytelling
                        </li>
                      </ul>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
            <Row>
              <Col sm="12" className="vertical-center">
                <img className="img-fluid" src={require("./images/serv-10.jpg")} alt=""/>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="content-card z1 p-40">
                  <Row>
                    <Col>
                      <p className="services-text">Organizamos <span
                        className="color-red font-weight-bold">eventos</span> y megaeventos, <span
                        className="color-red font-weight-bold">activaciones</span> y todo tipo de
                        <span className="color-red font-weight-bold"> experiencias</span></p>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
        <img className="rojo-left hide-md" src={require("./images/rojo.png")} alt=""/>
        <div className="section_wide section_black">
          <Container className="main-container section_padding">
            <Row>
              <Col className="text-center">
                <Row>
                  <Col>
                    <h4>Servicios</h4>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <h1>Además sumamos valor.</h1>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="padding-5">
              <Col md="4">
                <div className="content-card content-card-dwarf">
                  <p className="more-services-text">Desarrollamos alianzas estratégicas para garantizar un entregable
                    360.</p>
                </div>
              </Col>
              <Col md="4">
                <div className="content-card content-card-dwarf">
                  <p className="more-services-text">Contamos con una red de profesionales asociados expertos.</p>
                </div>
              </Col>
              <Col md="4">
                <div className="content-card content-card-dwarf">
                  <ul>
                    <li className="more-services-text">
                      <span className="content-span">+</span>Comunicación ATL
                    </li>
                    <li className="more-services-text">
                      <span className="content-span">+</span>Tecnología aplicada
                    </li>
                    <li className="more-services-text">
                      <span className="content-span">+</span>Organización de eventos
                    </li>
                  </ul>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <Container className="section_padding">
          <Col sm="12" className="hide-md">
            <img className="rojo-lessmargin" src={require("./images/rojo.png")} alt=""/>
          </Col>
          <Row>
            <Col md="8">
              <p className="logica">La lógica te llevará<br/>
                desde A hasta B.<br/>
                La imaginación te llevará a cualquier parte.</p>
              <span className="albert">
                  Albert Einstein
                </span>
            </Col>
            <Col className="hide-sm" md={{size: 3, offset: 1}}>
              <img className="rojo-lessmargin" src={require("./images/rojo.png")} alt=""/>
            </Col>
          </Row>
        </Container>
        <div className="section_wide section_red vertical-center" id="contacto">
          <Container className="main-container section_padding">
            <Container>
              <Row className="vertical-center">
                <Col md="8">
                  <h1>¿Iniciamos un proyecto?</h1>
                  <p className="mt-4 mp"><a href="mailto:info@laformula.com.ar" rel="noopener noreferrer"
                                            target="_blank" className="mt-4 mb-1 mp">info@laformula.com.ar</a></p>
                  <p className="mp">+54 9 11 4176 8086</p>
                  <p><a href="https://www.linkedin.com/company/la-f%C5%8Drmula" rel="noopener noreferrer"
                        target="_blank"><img className="icon" src={require("./images/linkedin.png")} alt=""/></a>
                    <a href="https://www.instagram.com/laformula.agency/" rel="noopener noreferrer" target="_blank"
                       className="ml-3"><img className="icon" src={require("./images/instagram.png")} alt=""/></a></p>
                </Col>
                <Col md={{size: 3, offset: 1}} className="hide-sm">
                  <img className="rojo-lessmargin" src={require("./images/rojo-copy-3.png")} alt=""/>
                </Col>
              </Row>
            </Container>
          </Container>
        </div>
        <div className="section_wide section_black">
          <Container className="main-container footer vertical-center">
            <Container>
              <Row>
                <Col xs="12" md="7" className="footer-logo">
                  <img className="exp-logo" src={require("./images/footer-logo.png")} alt="Logo"/>
                </Col>
                <Col xs="12" md="5" className="vertical-center">
                  <p className="text-right hide-sm">© 2020 All Rights Reserved.</p>
                  <p className="text-center hide-md">© 2020 All Rights Reserved.</p>
                </Col>
              </Row>
            </Container>
          </Container>
        </div>
      </div>
    );
  }
}

export default App;
